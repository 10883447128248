/** @jsx jsx */
import React from "react";
import { jsx, Link as TLink } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ArraySplit from "../Util/ArraySplit";
import SkillTag from "../Tags/SkillTag";

const ProjectRow = ({ project, idx }) => {
  const { frontmatter } = project;
  const reverse = idx % 2 === 1;
  return (
    <div
      sx={{
        marginY: 3,
        display: "flex",
        flexFlow: `wrap`,
        flexDirection: reverse ? "row-reverse" : "row",
      }}
    >
      <aside
        sx={{
          flexGrow: 1,
          flexBasis: "auto",
          textAlign: reverse ? "right" : "left",
        }}
      >
        <div>
          <h3 sx={{ marginTop: 0 }}>{frontmatter.title}</h3>
          <div sx={{ margin: "0 -0.75rem" }}>
            <ArraySplit
              arr={
                frontmatter.tags
                  ? frontmatter.tags.map((t) => <SkillTag name={t} />)
                  : []
              }
              sep=" - "
            />
          </div>
          <div>
            {frontmatter.live_url && (
              <TLink
                href={frontmatter.live_url}
                target="_blank"
                rel="noopener noreferrer"
                as="a"
              >
                <FontAwesomeIcon icon={["fas", "external-link-alt"]} />
              </TLink>
            )}
            <div sx={{ display: `inline-block`, width: `0.5rem` }} />
            {frontmatter.source_url && (
              <TLink
                href={frontmatter.source_url}
                target="_blank"
                rel="noopener noreferrer"
                as="a"
              >
                <FontAwesomeIcon icon={["fab", "github"]} />
              </TLink>
            )}
          </div>
        </div>
      </aside>

      <main
        sx={{
          flexGrow: 99999,
          flexBasis: 0,
          minWidth: 320,
          textAlign: reverse ? "left" : "right",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: frontmatter.summary }} />
      </main>
    </div>
  );
};

export default ProjectRow;
