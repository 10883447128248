/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { Component, useState } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import _ from "lodash";
import ArraySplit from "../components/Util/ArraySplit";
import CatLink from "../components/Util/CatLink";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import LightHr from "../components/Util/LightHr";
import ProjectRow from "../components/Projects/ProjectRow";
import { separator } from "../../defaults";

const ProjectsPage = ({ data, pageContext }) => {
  const projectsEdges = data.allMarkdownRemark.edges.filter(
    (edge) => edge.node.fields.shown_on_basepath
  );
  const { projectCategories, category } = pageContext;
  const projectCategoryLinks = projectCategories.map((cat) =>
    CatLink(cat, `/projects/${_.kebabCase(cat)}`, cat === category)
  );

  return (
    <Layout>
      <div className="projects-container">
        <Helmet title={`Projects | ${config.siteTitle}`} />
        <div sx={{ fontSize: 2 }}>
          <ArraySplit arr={projectCategoryLinks} sep={separator} />
        </div>

        {projectsEdges.map((edge, i) => {
          return (
            <div key={edge.node.fields.slug}>
              <ProjectRow project={edge.node} idx={i} />
              {i === projectsEdges.length - 1 ? <></> : <LightHr />}
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default ProjectsPage;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListProjectsQuery(
    $imageList: [String]
    $filter: MarkdownRemarkFilterInput
    $sort: MarkdownRemarkSortInput
  ) {
    allFile(filter: { relativePath: { in: $imageList } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          relativeDirectory
        }
      }
    }
    allMarkdownRemark(filter: $filter, sort: $sort) {
      edges {
        node {
          fields {
            slug
            date
            shown_on_basepath
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
            live_url
            source_url
            summary
            shown_on_home
            category
          }
        }
      }
    }
  }
`;
